import './style/style.scss';
import React, { useEffect, useState } from 'react';
import { GridMasonry } from './components/gridMasonry/gridMasonry';
import { Media, Sharing, Styling } from './Model/Touchpix';
import { loadData } from './services/loadTouchpixData';
import Modal from 'react-modal';
import { ModalGallery } from './components/modalGallery/modalGallery';
import { ShareButtonsGrid } from './components/shareButtonsGrid/shareButtonsGrid';
import { ModalEmail } from './components/modalEmail/modalEmail';
import { ModalDownload } from './components/modalDownload/modalDownload';
import { Spinner } from './components/spinner/spinner';
import { ModalEmailZip } from './components/modalEmailZip/modalEmailZip';
import { useHistory } from 'react-router';
import { ModalSlideshow } from './components/modalSlideshow/modalSlideshow';
import { SortIcon } from './components/icons/sortIcon';
import { ModalQR } from './components/modalQR/modalQR';

Modal.setAppElement('#root');

function App() {
    const [gallery, setGallery] = useState('');
    const [eventId, setEventId] = useState<number>();
    const [zipNonce, setZipNonce] = useState('');
    const [sharing, setSharing] = useState<Sharing>();
    const [styling, setStyling] = useState<Styling | undefined>();
    const [medias, setMedias] = useState<Array<Media>>([]);
    const [mediaToModal, setMediaToModal] = useState<Media | undefined>(undefined);
    const [singleMedia, setSingleMedia] = useState<boolean>(false);
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const [modalIsOpenEmail, setIsOpenEmail] = useState<boolean>(false);
    const [modalIsOpenDownload, setIsOpenDownload] = useState<boolean>(false);
    const [modalIsOpenZip, setIsOpenZip] = useState<boolean>(false);
    const [modalIsOpenSlideshow, setIsOpenSlideshow] = useState<boolean>(false);
    const [modalIsOpenQR, setIsOpenQR] = useState<boolean>(false);
    const history = useHistory();
    const [spinner, setSpinner] = useState<boolean>(true);
    const [errorMedias, setErrorMedias] = useState<boolean>(false);
    const [reverseGallery, setReverseGallery] = useState<boolean>(true);
    // const [overlay, setOverlay] = useState(0);

    // The first time check the url and get the string of the gallery to do the
    // request http of the gallery
    useEffect(() => {
        const url = new URL(window.location.href);

        // Get the string if the url is legacy
        const legacyUrl = url.searchParams.get('etoken') ? url.searchParams.get('etoken') : '';

        if (legacyUrl !== '') {
            history.push(window.location.origin + window.location.pathname + legacyUrl);
            window.location.reload();
        }

        const pathElements = url.pathname.split('/').filter((element) => element !== '');

        let galleryToLoad = pathElements[pathElements.length - 1];

        const currentFileName = url.searchParams.get('file');
        if (currentFileName) {
            galleryToLoad += '?file=' + currentFileName;
        }

        setGallery(galleryToLoad);
        loadData(galleryToLoad)
            .then((tp) => {
                setEventId(tp.event_id);
                setZipNonce(tp.zip_nonce);
                setSharing(tp.sharing);
                setStyling(tp.styling);
                setMedias(tp.media);
                setSpinner(false);
                document.title = 'Public Gallery View - ' + tp.styling.logo.alt_text;

                if (currentFileName !== null) {
                    if (tp.media.length === 0) {
                        setMediaToModal({
                            key: currentFileName,
                            src: 'not-found',
                            timestamp: 0,
                            type: '',
                            title: '',
                            thumb: '',
                        });
                    } else {
                        setMediaToModal(tp.media[0]);
                    }

                    setSingleMedia(true);
                    setIsOpen(true);
                }
            })
            .catch((error) => {
                if (currentFileName !== null) {
                    setMediaToModal({
                        key: currentFileName,
                        src: 'not-found',
                        timestamp: 0,
                        type: '',
                        title: '',
                        thumb: '',
                    });
                    setSingleMedia(true);
                    setIsOpen(true);
                }
                console.log('error', error);
            });
    }, []);

    // Check if the medias are undefined
    useEffect(() => {
        if (!medias) {
            setErrorMedias(true);
        }
    }, [medias]);

    // useEffect(() => {
    //     document.documentElement.style.setProperty('--background-button', 'orange');
    //     document.documentElement.style.setProperty('--color-icon', 'blue');
    // }, []);

    const onBackButtonEvent = (e: any) => {
        const url = new URL(window.location.href);
        const currentFileName = url.searchParams.get('file') ? url.searchParams.get('file') : '';
        if (currentFileName === '') {
            closeModal();
        } else {
            prevElement();
        }
    };

    // Open and close the media modal
    const openModal = (mediaKey: string) => {
        const media = medias?.find((element) => element.key === mediaKey);

        if (media) {
            setMediaToModal(media);
            changeUrl(media.key.split('/')[media.key.split('/').length - 1]);
            setIsOpen(true);
        }

        disableBodyScroll();
        window.addEventListener('popstate', onBackButtonEvent);
    };

    const closeModal = () => {
        if (singleMedia) {
            return;
        }
        setIsOpen(false);
        enableBodyScroll();
        history.push(window.location.pathname);
        setMediaToModal(undefined);
    };

    // Actions to set the actual media as the prev/next media.
    // Check if the actual media is the first or the last
    const prevElement = () => {
        const url = new URL(window.location.href);
        const currentFileName = url.searchParams.get('file') ? url.searchParams.get('file') : '';
        let newMedia;

        if (!mediaToModal && currentFileName) {
            setMediaToModal(medias.find((element) => element.key.includes(currentFileName)));
        }

        if (medias) {
            for (let index = 0; index < medias.length; index++) {
                const element = medias[index];
                if (element.key === mediaToModal?.key) {
                    if (index > 0) {
                        newMedia = medias[index - 1];
                    } else {
                        newMedia = medias[medias.length - 1];
                    }
                }
            }
        }

        if (newMedia) {
            changeUrl(newMedia.key.split('/')[newMedia.key.split('/').length - 1]);
            setMediaToModal(newMedia);
        }
    };

    const nextElement = () => {
        let newMedia;
        if (medias) {
            for (let index = 0; index < medias.length; index++) {
                const element = medias[index];
                if (element.key === mediaToModal?.key) {
                    if (index !== medias.length - 1) {
                        newMedia = medias[index + 1];
                    } else {
                        newMedia = medias[0];
                    }
                }
            }
        }
        if (newMedia) {
            changeUrl(newMedia.key.split('/')[newMedia.key.split('/').length - 1]);
            setMediaToModal(newMedia);
        }
    };

    const handleChangeOrder = () => {
        setReverseGallery(!reverseGallery);
    };

    // Open and close the QR modal
    const openModalQR = () => {
        setIsOpenQR(true);
    };

    const closeModalQR = () => {
        setIsOpenQR(false);
    };

    // Open and close the email modal
    const openModalEmail = () => {
        setIsOpenEmail(true);
    };

    const closeModalEmail = () => {
        setIsOpenEmail(false);
    };

    // Open and close the download modal
    const openModalDownload = () => {
        setIsOpenDownload(true);
    };

    const closeModalDownload = () => {
        setIsOpenDownload(false);
    };

    // Open and close the Zip modal
    const openModalZip = () => {
        setIsOpenZip(true);
    };

    const closeModalZip = () => {
        setIsOpenZip(false);
    };

    // Open and close the slideshow modal
    const openModalSlideshow = () => {
        setIsOpenSlideshow(true);
        disableBodyScroll();
    };

    const closeModalSlideshow = () => {
        setIsOpenSlideshow(false);
        enableBodyScroll();
    };

    const changeUrl = (newUrl: string) => {
        const urlToAdd = `?file=${newUrl}`;
        history.push(urlToAdd);
    };

    const sentEmailSuccessful = () => {
        const popover = document.getElementById('popover-email');
        if (popover) {
            popover.style.left = '0';
            setTimeout(() => {
                popover.style.left = '-300px';
            }, 2000);
        }
    };

    const disableBodyScroll = () => {
        document.body.style.overflow = 'hidden';
    };

    const enableBodyScroll = () => {
        document.body.style.overflow = 'auto';
    };

    return (
        <div
            className='App'
            style={{
                backgroundColor: styling?.background.color,
                backgroundImage: `url(${styling?.background.url})`,
            }}
        >
            <div className='container-logo'>
                <img src={styling?.logo.url} alt={styling?.logo.alt_text} />
            </div>
            <div className='container'>
                {sharing ? (
                    <div className='container-top-buttons'>
                        <div className='container-switch'>
                            <a
                                onClick={handleChangeOrder}
                                className={(reverseGallery ? 'reverse-gallery' : '') + ' button-share-touchpix'}
                            >
                                <SortIcon />
                            </a>
                        </div>
                        <ShareButtonsGrid
                            eventId={eventId ? eventId : 0}
                            filename={''}
                            sharing={['slideshow', 'email', 'downloadAllGallery']}
                            classStyle='buttons-gallery'
                            openModalEmail={openModalEmail}
                            openModalDownload={openModalDownload}
                            openModalSlideshow={openModalSlideshow}
                            openModalQR={openModalQR}
                        />
                    </div>
                ) : (
                    ''
                )}

                {medias ? (
                    <GridMasonry
                        medias={
                            reverseGallery
                                ? medias.sort((x, y) => {
                                      return y.timestamp - x.timestamp;
                                  })
                                : medias.sort((x, y) => {
                                      return x.timestamp - y.timestamp;
                                  })
                        }
                        clickOnMasonry={openModal}
                    />
                ) : (
                    ''
                )}

                {errorMedias ? (
                    <h1 className='text-wrong-gallery'>
                        Something went wrong while getting the gallery, please try again.
                    </h1>
                ) : (
                    ''
                )}

                {/* Gallery modal */}
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
                    <ModalGallery
                        eventId={eventId ? eventId : 0}
                        closeModal={closeModal}
                        prevElement={prevElement}
                        nextElement={nextElement}
                        media={mediaToModal}
                        sharing={sharing}
                        openModalEmail={openModalEmail}
                        singleMedia={singleMedia}
                    />
                </Modal>
                {/* Email modal */}
                <Modal isOpen={modalIsOpenEmail} onRequestClose={closeModalEmail} className='modal_email'>
                    <ModalEmail
                        sentEmailSuccessful={sentEmailSuccessful}
                        closeModalEmail={closeModalEmail}
                        eventId={eventId ? eventId : 0}
                    />
                </Modal>
                {/* Download modal */}
                <Modal isOpen={modalIsOpenDownload} onRequestClose={closeModalDownload} className='modal_email'>
                    <ModalDownload
                        closeModalDownload={closeModalDownload}
                        openModalZip={openModalZip}
                        eventId={eventId ? eventId : 0}
                        zipNonce={zipNonce}
                    />
                </Modal>
                {/* Download by email zip */}
                <Modal isOpen={modalIsOpenZip} onRequestClose={closeModalZip} className='modal_email'>
                    <ModalEmailZip
                        sentEmailSuccessful={sentEmailSuccessful}
                        closeModalZip={closeModalZip}
                        eventId={eventId ? eventId : 0}
                    />
                </Modal>
                {/* Gallery slideshow */}
                <Modal isOpen={modalIsOpenSlideshow} onRequestClose={closeModalSlideshow}>
                    <ModalSlideshow
                        eventId={eventId ? eventId : 0}
                        closeModal={closeModalSlideshow}
                        galleryToLoad={gallery}
                        medias={medias}
                    />
                </Modal>
                {/* QR */}
                <Modal isOpen={modalIsOpenQR} onRequestClose={closeModalQR} className='modal_email'>
                    <ModalQR closeModalQR={closeModalQR} />
                </Modal>
            </div>
            <div id='popover-email' className='popover-content'>
                <p className='popover-message'>Email has been sent!</p>
            </div>
            {spinner ? <Spinner /> : ''}
        </div>
    );
}

export default App;
