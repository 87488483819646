// Request to send zip of the gallery by email
export const sendZipByEmail = async (
    id: number,
    email: string
): Promise<string> => {
    const apiUrl = `https://api.touchpix.com/wp-json/tpxac/v1/gallery/${id}/subscribe`
    const formData = new FormData()
    formData.append('email', email)

    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                resolve('200')
            })
            .catch((error) => {
                console.log(error)
                reject('400')
            })
    })
}
