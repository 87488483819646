export const getCountryCode = async (): Promise<string> => {
    const apiKey = 'taHyJ7Zy5L2rDUz';
    const apiUrl = `https://pro.ip-api.com/json/?key=${apiKey}`;

    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data.countryCode.toLowerCase());
            })
            .catch((error) => {
                resolve('us');
            });
    });
};
