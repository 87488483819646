// Request to send sharing stats
export const sendStats = async (
    id: number,
    action: string,
    filename: string,
): Promise<string> => {
    const apiUrl = `https://api.touchpix.com/wp-json/tpxac/v1/gallery/${id}/stats`
    const formData = new FormData()
    formData.append('action', action)
    formData.append('filename', filename)

    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                resolve('200')
            })
            .catch((error) => {
                console.log(error)
                reject('400')
            })
    })
}
