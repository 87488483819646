import { FC } from 'react'
import { Media } from '../../Model/Touchpix'

interface Props {
    media: Media
    clickOnImage: any
}

// Component get single media and display the thumb of the media and, if is a video,
// display the play button
export const ImageGrid: FC<Props> = ({ media, clickOnImage }) => {
    const handleClick = (mediaKey: string) => {
        clickOnImage(mediaKey)
    }
    return (
        <div className="image-grid" onClick={() => handleClick(media.key)}>
            {media.type === 'video' ? (
                <img
                    src={process.env.PUBLIC_URL + '/images/play-button.svg'}
                    alt="Play-button"
                    className="play-button"
                />
            ) : (
                ''
            )}
            <img src={media.thumb} alt={media.title} />
        </div>
    )
}
