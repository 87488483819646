import { FC } from 'react';

interface Props {}

export const Spinner: FC<Props> = (props) => {
    return (
        <div className='spinner-home'>
            <img
                src={process.env.PUBLIC_URL + `/images/loading.png?version=${process.env.REACT_APP_VERSION}`}
                alt='spinner-loading'
            />
        </div>
    );
};
