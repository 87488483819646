import { FC, useEffect, useState } from 'react';
import { Media, Sharing } from '../../Model/Touchpix';
import { ShareButtonsGrid } from '../shareButtonsGrid/shareButtonsGrid';

interface Props {
    eventId: number;
    media: Media | undefined;
    sharing?: Sharing;
    closeModal: any;
    prevElement: any;
    nextElement: any;
    openModalEmail?: any;
    singleMedia: boolean;
}

enum MediaStatus {
    LOADING,
    UPLOADING,
    OLD,
    ERROR,
    LOADED,
}

// Have the buttons to change the media of the modal. Also have the grid for sharing
// according the gallery
export const ModalGallery: FC<Props> = (props) => {
    const [mediaStatus, setMediaStatus] = useState<MediaStatus>(MediaStatus.LOADING);

    useEffect(() => {
        if (!props.media) {
            return;
        } else if (props.media.src === 'not-found' && isMoreThanOneWeek(props.media.key)) {
            setMediaStatus(MediaStatus.OLD);
        } else if (props.media.src === 'not-found' && !isMoreThanOneWeek(props.media.key)) {
            setMediaStatus(MediaStatus.UPLOADING);
        } else {
            setMediaStatus(MediaStatus.LOADED);
        }
    }, [props.media]);

    useEffect(() => {
        const timeoutEmpty = setTimeout(() => {
            window.location.reload();
        }, 10000);

        if (mediaStatus !== MediaStatus.UPLOADING) {
            clearTimeout(timeoutEmpty);
        }

        return () => {
            clearTimeout(timeoutEmpty);
        };
    }, [mediaStatus]);

    useEffect(() => {
        const video = document.getElementsByTagName('video')[0];
        if (video) {
            video.volume = 0.5;
        }
    }, [props.media]);

    const isMoreThanOneWeek = (media: string) => {
        const mediaDate = media.split('-')[1];
        const date = new Date(+mediaDate.slice(0, 4), +mediaDate.slice(4, 6) - 1, +mediaDate.slice(6, 8));

        const currentDate = new Date();
        const timeDiff = currentDate.getTime() - date.getTime();
        const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;

        return timeDiff >= oneWeekInMs;
    };

    return (
        <div className='modal-content'>
            <button
                className={`button-modal close ${props.singleMedia ? 'hiddeButton' : ''}`}
                onClick={props.closeModal}
            >
                <img src={process.env.PUBLIC_URL + '/images/close-button.svg'} alt='close-modal' />
            </button>
            <button
                className={`button-modal prev ${props.singleMedia ? 'hiddeButton' : ''}`}
                onClick={props.prevElement}
            >
                <img src={process.env.PUBLIC_URL + '/images/arrow-next.svg'} alt='previous-element' />
            </button>
            <button
                className={`button-modal next ${props.singleMedia ? 'hiddeButton' : ''}`}
                onClick={props.nextElement}
            >
                <img src={process.env.PUBLIC_URL + '/images/arrow-next.svg'} alt='next-element' />
            </button>

            {mediaStatus === MediaStatus.LOADING && (
                <div className='uploading-file'>
                    <img src={process.env.PUBLIC_URL + '/images/loading.png'} alt='spinner-loading' />
                </div>
            )}

            {mediaStatus === MediaStatus.OLD && (
                <div className='uploading-file'>
                    <p>
                        There seems to be an issue with the file. If you have any questions, please contact with the
                        creator of this gallery.
                    </p>
                </div>
            )}

            {mediaStatus === MediaStatus.UPLOADING && (
                <div className='uploading-file'>
                    <img src={process.env.PUBLIC_URL + '/images/loading.png'} alt='spinner-loading' />
                    <p>Stay tuned, your file is coming soon.</p>
                </div>
            )}

            {mediaStatus === MediaStatus.LOADED &&
                props.media &&
                (props.media.type === 'video' ? (
                    <video key={props.media.key} className='media-content' controls autoPlay>
                        <source src={props.media.src} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img className='media-content' src={props.media.src} alt={props.media.title} />
                ))}
            {props.sharing && mediaStatus === MediaStatus.LOADED ? (
                <ShareButtonsGrid
                    eventId={props.eventId}
                    filename={props.media?.title ? props.media.title : ''}
                    sharing={props.sharing.options.map((e) => e)}
                    classStyle='buttons-modal-touchpix'
                    openModalEmail={props.openModalEmail}
                />
            ) : (
                ''
            )}
        </div>
    );
};
