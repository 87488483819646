import { FC } from 'react';
import { copyUrl, downloadUrl, facebookUrl, urlTwitter, whatsappUrl } from '../../services/sharing';
import { ButtonShare } from '../buttonShare/buttonShare';
import { CopyIcon } from '../icons/copyIcon';
import { DownloadIcon } from '../icons/downloadIcon';
import { EnvelopeIcon } from '../icons/envelopeIcon';
import { FacebookIcon } from '../icons/facebookIcon';
import { SlideshowIcon } from '../icons/slideshowIcon';
import { TwitterIcon } from '../icons/twitterIcon';
import { WhatsappIcon } from '../icons/whatsappIcon';
import { QRCodeIcon } from '../icons/qrCodeIcon';

interface Props {
    eventId: number;
    filename: string;
    sharing: Array<string>;
    classStyle: string;
    openModalEmail?: any;
    openModalDownload?: any;
    openModalSlideshow?: any;
    openModalQR?: any;
}

// For every element in the array of sharing, return the component button
// for this shared method.
export const ShareButtonsGrid: FC<Props> = (props) => {
    const displayButton = (type: string) => {
        switch (type) {
            case 'qr-gallery':
                return (
                    <ButtonShare
                        key={type}
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Show QR'
                        image={<QRCodeIcon />}
                        link=''
                        qr
                        target='_self'
                        openModalQR={props.openModalQR}
                    />
                );
                break;
            case 'slideshow':
                return (
                    <ButtonShare
                        key={type}
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Open slideshow'
                        image={<SlideshowIcon />}
                        link=''
                        email
                        target='_self'
                        openModalEmail={props.openModalSlideshow}
                    />
                );
                break;
            case 'email':
                return (
                    <ButtonShare
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Send gallery by email'
                        image={<EnvelopeIcon />}
                        link=''
                        target='_self'
                        key='send-email'
                        email
                        openModalEmail={props.openModalEmail}
                    />
                );
                break;
            case 'download':
                return (
                    <ButtonShare
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Download'
                        image={<DownloadIcon />}
                        link={downloadUrl()}
                        key='download'
                        target='_blank'
                        download
                    />
                );
                break;
            case 'downloadAllGallery':
                return (
                    <ButtonShare
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Download gallery (zip)'
                        image={<DownloadIcon />}
                        link={downloadUrl()}
                        key='download'
                        target='_blank'
                        downloadAllGallery
                        openModalDownload={props.openModalDownload}
                    />
                );
                break;
            case 'facebook':
                return (
                    <ButtonShare
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Facebook'
                        image={<FacebookIcon />}
                        link={facebookUrl()}
                        target='_blank'
                        key='Facebook'
                    />
                );
                break;
            case 'whatsapp':
                return (
                    <ButtonShare
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Whatsapp'
                        image={<WhatsappIcon />}
                        link={whatsappUrl()}
                        target='_blank'
                        key='Whatsapp'
                    />
                );
                break;
            case 'twitter':
                return (
                    <ButtonShare
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Twitter'
                        image={<TwitterIcon />}
                        link={urlTwitter()}
                        target='_blank'
                        key='Twitter'
                    />
                );
                break;
            case 'copy':
                return (
                    <ButtonShare
                        eventId={props.eventId}
                        filename={props.filename}
                        alt='Copy'
                        image={<CopyIcon />}
                        link={copyUrl()}
                        target='_self'
                        key='Copy'
                        copy
                    />
                );
                break;

            default:
                break;
        }
    };
    return <div className={'grid-buttons ' + props.classStyle}>{props.sharing.map((e) => displayButton(e))}</div>;
};
