import { FC } from 'react';
import { QRCodeSVG } from 'qrcode.react';

interface Props {
    closeModalQR: any;
}

// Modal to display QR with the url
export const ModalQR: FC<Props> = (props) => {
    return (
        <div>
            <button className='button-modal close' onClick={props.closeModalQR}>
                <img src={process.env.PUBLIC_URL + '/images/close-button.svg'} alt='close-modal' />
                <span className='close-sm'>CLOSE</span>
            </button>
            <div className='container-modal-email'>
                <QRCodeSVG value={window.location.href} size={200} />
            </div>
        </div>
    );
};
