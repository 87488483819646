import { createElement, FC, useEffect, useState } from 'react';
import { JsxElement } from 'typescript';
import { downloadMedia } from '../../services/downloadMedia';
import { sendStats } from '../../services/sendStats';

interface Props {
    eventId: number;
    filename: string;
    alt: string;
    image: JSX.Element;
    link: string;
    target: string;
    download?: boolean;
    downloadAllGallery?: boolean;
    openModalDownload?: any;
    email?: boolean;
    openModalEmail?: any;
    openModalQR?: any;
    copy?: boolean;
    qr?: boolean;
}

// Return the button according if the button is to download, copy, etc.
// Each button have different functions
export const ButtonShare: FC<Props> = (props) => {
    const [downloadUrl, setdownloadUrl] = useState<string>();
    const [loaderDownload, setLoaderDownload] = useState(false);

    // The download button need the link to download, do the request to get
    // the AWS link to download the media
    useEffect(() => {
        if (props.download) {
            const url = new URL(window.location.href);
            const path = url.pathname.split('/');
            let gallery = '';

            // Check if the last element of path is '/'
            if (path[url.pathname.split('/').length - 1] === '') {
                gallery = path[url.pathname.split('/').length - 2];
            } else {
                gallery = path[url.pathname.split('/').length - 1];
            }
            const file = url.searchParams.get('file');

            if (file) {
                downloadMedia(gallery, file)
                    .then((response) => {
                        setdownloadUrl(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }, [props.link]);

    const handleClickCopy = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(props.link);
        sendStats(props.eventId, 'copy', props.filename);
        const popover = document.getElementById('popover');
        if (popover) {
            popover.style.left = '0';
            setTimeout(() => {
                popover.style.left = '-300px';
            }, 2000);
        }
    };

    const handleClickShare = (target: string) => {
        const share = target.toLowerCase();
        if (['facebook', 'twitter', 'whatsapp', 'copy'].includes(share)) {
            sendStats(props.eventId, share, props.filename);
        }
    };

    const donwload = async (url: string) => {
        console.log('isIOS', isIOS() && navigator.canShare && navigator.canShare({ url }));
        console.log('url', url);
        if (isIOS() && navigator.canShare && navigator.canShare({ url })) {
            setLoaderDownload(true);
            const response = await fetch(url);
            const blob = await response.blob();

            const fileName = url.split('/').pop() ?? '';
            const file = new File([blob], fileName, { type: blob.type });
            navigator
                .share({
                    title: 'Check out this file',
                    text: 'Here is the file you wanted to download:',
                    files: [file],
                })
                .then(() => {
                    setLoaderDownload(false);
                })
                .catch((error) => {
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = url.split('/').pop() || 'download';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setLoaderDownload(false);
                });
        } else {
            const a = document.createElement('a');
            a.href = url;
            a.download = url.split('/').pop() || 'download';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    };

    if (props.download) {
        return (
            <a
                className='button-share-touchpix'
                rel='nofollow noopener'
                target={props.target}
                onClick={() => {
                    donwload(downloadUrl ?? '');
                    handleClickShare('download');
                }}
            >
                {loaderDownload ? <div className='spinner'></div> : props.image}
            </a>
        );
    } else if (props.downloadAllGallery) {
        return (
            <a
                className='button-share-touchpix'
                rel='nofollow noopener'
                target={props.target}
                onClick={props.openModalDownload}
            >
                {props.image}
            </a>
        );
    } else if (props.email) {
        return (
            <a
                className='button-share-touchpix'
                data-link={props.link}
                rel='nofollow noopener'
                target={props.target}
                onClick={props.openModalEmail}
            >
                {props.image}
            </a>
        );
    } else if (props.copy) {
        return (
            <div>
                <a
                    className='button-share-touchpix'
                    data-link={props.link}
                    rel='nofollow noopener'
                    target={props.target}
                    onClick={handleClickCopy}
                >
                    {props.image}
                </a>
                <div id='popover' className='popover-content'>
                    <p className='popover-message'>Link has been copied!</p>
                </div>
            </div>
        );
    } else if (props.qr) {
        return (
            <a
                className='button-share-touchpix'
                rel='nofollow noopener'
                target={props.target}
                onClick={props.openModalQR}
            >
                {props.image}
            </a>
        );
    } else {
        return (
            <a
                className='button-share-touchpix'
                href={props.link}
                rel='nofollow noopener'
                target={props.target}
                onClick={() => handleClickShare(props.alt)}
            >
                {props.image}
            </a>
        );
    }
};
