export const SortIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='502.531'
            height='497'
            viewBox='0 0 502.531 497'
            fill='var(--color-icon)'
        >
            <g id='Group_1' data-name='Group 1' transform='translate(-387.469 -279)'>
                <path
                    id='Union_1'
                    data-name='Union 1'
                    d='M369.816,488.835l-98.139-98.709a25,25,0,0,1,35.356-35.356L363.3,411.035V104a25,25,0,0,1,50,0V411.681l56.911-56.911a25,25,0,0,1,35.356,35.356l-99.726,99.7,0,0a25,25,0,0,1-36.017-.984ZM109.943,393V85.319L53.033,142.23a25,25,0,0,1-35.356-35.356L117.4,7.178l.005,0a25,25,0,0,1,36.016.984l98.139,98.709a25,25,0,0,1-35.356,35.356L159.943,85.964V393a25,25,0,0,1-50,0Z'
                    transform='translate(377.114 279)'
                />
            </g>
        </svg>
    );
};
