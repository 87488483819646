import { FC, useEffect, useState } from 'react';
import { Media } from '../../Model/Touchpix';
import { loadData, loadDataSince } from '../../services/loadTouchpixData';
import { QRCodeSVG } from 'qrcode.react';
import useInterval from '../../hooks/useInterval';

interface Props {
    eventId: number;
    galleryToLoad: string;
    medias: Media[];
    closeModal: any;
}

export const ModalSlideshow: FC<Props> = (props) => {
    const [showLastMedia, setShowLastMedia] = useState(true);
    const [medias, setMedias] = useState<Media[]>(props.medias);

    const [prevMedia, setPrevMedia] = useState<Media>();
    const [media, setMedia] = useState<Media>();
    const [nextMedia, setNextMedia] = useState<Media>();

    const [lastMedia, setLastMedia] = useState(0);
    let timeoutImg: any = undefined;

    useEffect(() => {
        if (props.medias.length > 0) {
            setLastMedia(getLastMedia(props.medias));
        }
    }, [props.medias]);

    useInterval(() => {
        clearTimeout(timeoutImg);
        loadData(props.galleryToLoad)
            .then((res) => {
                setMedias(res.media);
            })
            .catch((err) => console.log(err));
    }, 120000);

    useEffect(() => {
        const newPrevMedia = medias[randomInt(0, medias.length - 1)];
        const newMedia = medias[randomInt(0, medias.length - 1)];
        const newNextMedia = getNextMedia(newMedia);

        setPrevMedia(newPrevMedia);
        setMedia(newMedia);
        setNextMedia(newNextMedia);
    }, [medias]);

    const onEnded = (key: string) => {
        if (media && media.key === key) {
            changeMedias();
        }
    };

    const changeMedias = () => {
        setMedia(undefined);
        setPrevMedia(media);
        setMedia(nextMedia);
        setNextMedia(getNextMedia(nextMedia as Media));
    };

    const getNextMedia = (nextMedia: Media) => {
        let random = randomInt(0, medias.length - 1);
        if (medias.length > 1) {
            while (nextMedia.key === medias[random].key) {
                random = randomInt(0, medias.length - 1);
            }
        }

        return medias[random];
    };

    const randomInt = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getLastMedia = (medias: Media[]) => {
        const timestamps = medias.map((md) => md.timestamp);
        return Math.max(...timestamps);
    };

    const deleteMedia = (media: Media) => {
        const newMedias = medias.filter((m) => m.key !== media.key);
        setMedias(newMedias);
    };

    return (
        <div className='modal-contenta'>
            <button className={`button-modal qr`} onClick={() => setShowLastMedia(!showLastMedia)}>
                <img src={process.env.PUBLIC_URL + '/images/qr_code.svg'} alt='show-hide carousel QR' />
            </button>
            <button className={`button-modal close`} onClick={props.closeModal}>
                <img src={process.env.PUBLIC_URL + '/images/close-button.svg'} alt='close-modal' />
            </button>
            <div className='container-modal-slideshow'>
                {medias.length === 0 ? (
                    <div className='slideshow'>
                        <p className='text-no-events'>The event has no files</p>
                    </div>
                ) : (
                    <div className={`slideshow ${!showLastMedia && 'full-size'}`}>
                        <div className='prev-img'>
                            <img className='media-content' src={prevMedia?.thumb} alt={nextMedia?.title} />
                        </div>
                        <div className='container-media-slideshow'>
                            {media && media.type === 'video' ? (
                                <video
                                    key={media.key}
                                    className='media-content media-slideshow'
                                    controls
                                    autoPlay
                                    onEnded={() => onEnded(media ? media.key : '')}
                                    onError={() => onEnded(media ? media.key : '')}
                                    loop={medias.length === 1 ? true : false}
                                >
                                    <source src={media.src} type='video/mp4' />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img
                                    className='media-content media-slideshow'
                                    src={media?.src}
                                    alt={media?.title}
                                    onLoad={() => {
                                        setTimeout(() => onEnded(media ? media.key : ''), 6000);
                                    }}
                                    onError={() => onEnded(media ? media.key : '')}
                                />
                            )}
                        </div>
                        <div className='next-img'>
                            <img
                                className='media-content'
                                src={nextMedia?.thumb}
                                alt={nextMedia?.title}
                                onError={() => nextMedia && getNextMedia(nextMedia)}
                            />
                        </div>
                    </div>
                )}
                {showLastMedia && (
                    <div className='container-last-medias'>
                        {medias
                            .sort((a, b) => b.timestamp - a.timestamp)
                            .slice(0, 4)
                            .map((media) => {
                                return (
                                    <div className='container-media-qr' key={media.key}>
                                        <img
                                            className='media-qr'
                                            src={media.thumb}
                                            alt={media.title}
                                            onError={() => deleteMedia(media)}
                                        />
                                        <QRCodeSVG
                                            value={`https://phtbth-upload.com/public/${props.galleryToLoad}?file=${
                                                media.key.split('/')[media.key.split('/').length - 1]
                                            }`}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </div>
    );
};
