import Masonry from 'react-masonry-css'
import { Media } from '../../Model/Touchpix'
import { FC } from 'react'
import { ImageGrid } from '../imageGrid/imageGrid'

interface Props {
    medias: Array<Media>
    clickOnMasonry: any
}

// Component gets all the medias and do the masonry of all medias
export const GridMasonry: FC<Props> = ({ medias, clickOnMasonry }) => {
    const breakpointColumnsObj = {
        default: 6,
        1100: 4,
        700: 3,
        500: 2,
    }

    const handleClick = (mediaKey: string) => {
        clickOnMasonry(mediaKey)
    }

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {medias.map((element) => {
                return (
                    <ImageGrid
                        media={element}
                        key={element.key}
                        clickOnImage={handleClick}
                    />
                )
            })}
        </Masonry>
    )
}
