// Request to get the download url
// The download url is different because AWS have another url if you want download
export const downloadMedia = async (
    gallery: string,
    file: string
): Promise<string> => {
    const apiUrl = `https://api.touchpix.com/wp-json/tpxac/v1/gallery/${gallery}/download?file=${file}`
    const formData = new FormData()
    formData.append('file', file)

    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data.url)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
