import { FC, useEffect, useState } from 'react';
import { downloadZipGallery } from '../../services/downloadZipGallery';

interface Props {
    closeModalDownload: any;
    openModalZip: any;
    eventId: number;
    zipNonce: string;
}

// Modal to download, have a spinner since the gallery zip is ready
export const ModalDownload: FC<Props> = (props) => {
    const [titleText, setTitleText] = useState<string>('Download gallery');
    const [urlDownload, setUrlDownload] = useState<string>('');
    const [activateSpinner, setActivateSpinner] = useState<boolean>(true);
    let interval: any;

    useEffect(() => {
        let isSubscribed = true;
        // First time do the call and wait response
        downloadZipGallery(props.eventId, 'GET', props.zipNonce)
            .then((response) => {
                if (isSubscribed) {
                    if (response === 'media_files_not_found') {
                        setTitleText('The gallery has no content yet. Please try again later');
                        setActivateSpinner(false);
                    } else if (response === 'zip_not_found') {
                        // If the zip is being generated, try the call every 5 secs
                        // 8 times, then show the popup to get the link to download by email
                        setTitleText('A zip of the gallery is being generated. Please wait');
                        downloadZipGallery(props.eventId, 'POST', props.zipNonce).then((resp) => {
                            let counter: number = 0;
                            interval = setInterval(async () => {
                                counter++;
                                if (counter < 8) {
                                    downloadZipGallery(props.eventId, 'GET', props.zipNonce)
                                        .then((res) => {
                                            if (res !== 'zip_not_found') {
                                                setTitleText('Gallery Download Generated');
                                                setUrlDownload(res);
                                                setActivateSpinner(false);
                                                clearInterval(interval);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                } else {
                                    clearInterval(interval);
                                    props.closeModalDownload();
                                    props.openModalZip();
                                }
                            }, 5000);
                        });
                    } else {
                        setTitleText('Gallery Download Generated');
                        setActivateSpinner(false);
                        setUrlDownload(response);
                    }
                }
            })
            .catch((error) => {
                setTitleText('An error has ocurred, please try again');
                setActivateSpinner(false);
                console.log(error);
            });
        return () => {
            isSubscribed = false;
            clearInterval(interval);
        };
    }, []);

    const closeModal = () => {
        //clearInterval(interval)
        props.closeModalDownload();
    };

    return (
        <div className='modal-email'>
            <button className='button-modal close' onClick={closeModal}>
                <img src={process.env.PUBLIC_URL + '/images/close-button.svg'} alt='close-modal' />
                <span className='close-sm'>CLOSE</span>
            </button>
            <div className='container-modal-email'>
                <h1>{titleText}</h1>
                <div className='container-modal'>
                    {activateSpinner ? <div className='spinner'></div> : ''}
                    {urlDownload !== '' ? (
                        <a href={urlDownload} download onClick={props.closeModalDownload}>
                            DOWNLOAD
                        </a>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};
