// Request to get the zip file of all gallery
export const downloadZipGallery = async (id: number, method: string, zipNonce: string): Promise<string> => {
    const apiUrl = `https://api.touchpix.com/wp-json/tpxac/v1/gallery/${id}/zip`;

    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: method,
            headers: {
                'x-tpx-zip-nonce': zipNonce,
            },
        })
            .then((response) => {
                if (200 === response.status) {
                    const url = response.headers ? response.headers.get('x-tpx-zip-location') : null;
                    if (url) {
                        resolve(url);
                    } else {
                        resolve('200');
                    }
                } else if (response.status === 404) {
                    const error = response.headers ? response.headers.get('x-tpx-error') : null;
                    if (error === 'media_files_not_found') {
                        resolve('media_files_not_found');
                    } else if (error === 'zip_not_found') {
                        resolve('zip_not_found');
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                reject('400');
            });
    });
};
