import { FC, useState } from 'react'
import { sendZipByEmail } from '../../services/sendZipByEmail'

interface Props {
    closeModalZip: any
    sentEmailSuccessful: any
    eventId: number
}

// Modal to send by email, do the form to set the email
export const ModalEmailZip: FC<Props> = (props) => {
    const [email, setEmail] = useState<string>('')

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        sendZipByEmail(props.eventId, email).then(() => {
            props.closeModalZip()
            props.sentEmailSuccessful()
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    return (
        <div className="modal-email">
            <button
                className="button-modal close"
                onClick={props.closeModalZip}
            >
                <img
                    src={process.env.PUBLIC_URL + '/images/close-button.svg'}
                    alt="close-modal"
                />
                <span className="close-sm">CLOSE</span>
            </button>
            <div className="container-modal-email">
                <h1>Share by email</h1>
                <p>
                    Generating of the zip will take a while. If you leave your
                    e-mail address you will be notified when it's finished
                </p>
                <form onSubmit={handleSubmit}>
                    <input onChange={handleChange} type="email" />
                    <button>SEND</button>
                </form>
            </div>
        </div>
    )
}
