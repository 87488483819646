import '../style/style.scss';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { getCountryCode } from '../services/getCountryCode';

const QRGenerator = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [sms, setSms] = useState('');
    const [smsError, setSmsError] = useState(false);
    const [whatsapp, setWhatsapp] = useState('');
    const [whatsappError, setWhatsappError] = useState(false);

    const [validQR, setValidQR] = useState(false);
    const [countryCode, setCountryCode] = useState('us');

    const [value, setValue] = useState({
        email: '',
        sms: '',
        whatsapp: '',
        qr_trigger_version: '1',
    });

    useEffect(() => {
        const getCountry = async () => {
            const code = await getCountryCode();
            setCountryCode(code);
        };

        getCountry();
    }, []);

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email: string) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        } else {
            return false;
        }
    };

    const handleClick = () => {
        setEmailError(false);
        setSmsError(false);
        setWhatsappError(false);

        const validatedEmail = validateEmail(email);
        const smsFormatted = sms === '' ? '' : `+${sms}`;
        const whatsappFormatted = whatsapp === '' ? '' : `+${whatsapp}`;

        if (email !== '' && !validatedEmail) {
            setEmailError(true);
            setValidQR(false);
            return;
        }

        if (sms !== '' && sms.length < 6) {
            setSmsError(true);
            setValidQR(false);
            return;
        }

        if (whatsapp !== '' && whatsapp.length < 6) {
            setWhatsappError(true);
            setValidQR(false);
            return;
        }

        if (email !== '' || sms !== '' || whatsapp !== '') {
            setValidQR(true);
            const valueQR = {
                email: email,
                sms: smsFormatted,
                whatsapp: whatsappFormatted,
                qr_trigger_version: '1',
            };
            setValue(valueQR);
        }
    };

    return (
        <div className='qr-generator'>
            <div className='card'>
                <h1>Create a QR code</h1>
                <p>
                    Use the QR code to start the session. After the session you will automatically receive the file
                    using SMS, Whatsapp or email.
                </p>
                <div className='container-input react-tel-input email'>
                    <label htmlFor='email'>Email</label>
                    <input
                        onChange={handleEmail}
                        type='email'
                        name='email'
                        className={`form-control ${emailError && 'error'}`}
                    />
                    <img src={process.env.PUBLIC_URL + '/images/envelope.svg'} alt='email' />
                </div>
                <div className='container-input'>
                    <label htmlFor='sms'>Sms</label>
                    <PhoneInput
                        value={sms}
                        onChange={(val) => setSms(val)}
                        enableSearch
                        country={countryCode}
                        placeholder='+0123456789'
                        inputClass={`${smsError && 'error'}`}
                        inputProps={{
                            name: 'sms',
                            required: true,
                            autoFocus: true,
                        }}
                    />
                </div>
                <div className='container-input'>
                    <label htmlFor='whatsapp'>Whatsapp</label>
                    <PhoneInput
                        value={whatsapp}
                        onChange={(val) => setWhatsapp(val)}
                        enableSearch
                        country={countryCode}
                        placeholder='+0123456789'
                        inputClass={`${whatsappError && 'error'}`}
                        inputProps={{
                            name: 'whatsapp',
                            required: true,
                            autoFocus: true,
                        }}
                    />
                </div>
                <div className='container-button'>
                    <button onClick={handleClick}>Generate</button>
                </div>
                <div className='container-qr'>
                    {validQR ? (
                        <QRCodeSVG value={JSON.stringify(value)} size={200} />
                    ) : (
                        <div className='no-qr'>
                            <p>Enter valid field(s)</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QRGenerator;
