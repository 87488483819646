import { Touchpix } from '../Model/Touchpix';

// Request for load all the gallery acoording a key of gallery
// Return a object Touchpix with all elements
const url: string = 'https://api.touchpix.com/wp-json/tpxac/v1/gallery/';

export const loadData = async (key: string): Promise<Touchpix> => {
    const apiUrl = url + key;
    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                var parsedData: Touchpix = data;

                resolve(parsedData);
            })
            .catch((error) => {
                console.error('Error displaying gallery');
                console.error(error);
                reject(error);
            });
    });
};

export const loadDataSince = async (key: string, since: number): Promise<Touchpix> => {
    const apiUrl = `${url}${key}?modified_since=${since}`;

    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                var parsedData: Touchpix = data;

                resolve(parsedData);
            })
            .catch((error) => {
                console.error('Error displaying gallery');
                console.error(error);
                reject(error);
            });
    });
};
